          
<template>

  <v-card color="rgba(255,255,255,0.01)" :dark="AppisDarkMode" flat height="100%" >
  

  
    
      <v-toolbar dark color="accent" class="stickytopbanner2" width="100%" id="recordtoolbar">
  <v-menu
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined fab small class="elevation-6 mx-1" dark><v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
      </template>
      <v-list  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
     
      <v-list-item @click="ToggleHelp()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Help
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="UpdateContact()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Save
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="green">mdi-content-save</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="EditContact()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Edit
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-pencil</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>                        
   </v-menu>       
   <v-toolbar-title class="hidden-sm-and-down">
     <span class="headline" >Contact - </span><span class="headline font-weight-light">{{ Full_Name  }}</span>
   </v-toolbar-title>
   <v-spacer></v-spacer>       
            <v-btn class="leftpadded" dark v-for="item in BannerTabs" :key="item.itemObjKey" @click="NavigatetoTab(item)" small  text><v-icon small >{{item.Icon}}</v-icon><span class="hidden-sm-and-down">{{item.Name}}</span></v-btn>       
 </v-toolbar> 

  
     <v-parallax class="recordparallax" v-if="AppisDarkMode"
        height="150"
            src="@/assets/RASolidA-Light.jpeg"
        >               
        </v-parallax>
        <v-parallax class="recordparallax" v-if="!AppisDarkMode"
        height="150"
            src="@/assets/RASolidA.jpeg"
        >               
        </v-parallax>
  <LookupComponent :ParentCollection="ParentCollection" :ParentTitle="ParentTitle" @LinkParent="LinkParent" @CancelSelection="CancelSelection" @CancelAddParentDialog="CancelAddParentDialog" :LookupArrayHeaders="LookupArrayHeaders" :LookupArray="LookupArray" :FieldFilter="FieldFilter" :RelatedObj="RelatedObj" :NewParentAdditional="NewParentAdditional" :ParentLookup="ParentLookup" :NewParentPrimary="NewParentPrimary" :ViewExistingLookupDialog="ViewExistingLookupDialog" :AddNewParentLookupDialog="AddNewParentLookupDialog"/>
  <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
    <SystemTicketsLogging @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
      </v-card>
    </v-dialog>
  <!-- <v-layout row class="stickytopleft mx-3">
  <v-btn @click="ToggleHelp()" color="pop" dark
    fab
    small
    top
    left><v-icon>mdi-help</v-icon></v-btn>
  </v-layout> -->
  <v-layout row class="stickytopright mx-3" id="recordtoolbar2">
    <v-btn @click="UpdateContact()" color="green" dark
      fab
      small                
      top
      right><v-icon>mdi-content-save</v-icon></v-btn>
  </v-layout>
  

  
  
    

  
    <v-bottom-sheet v-model="helpsheet">
    <v-sheet class="text-center" height="200px">
      <v-btn
        class="mt-6"
        dark
        color="red"
        @click="helpsheet = !helpsheet"
      >close</v-btn>
      <div>This is the Contact help sheet. What would you want to know?</div>
      <v-btn @click="ActivateTicketLog()">Log Ticket <v-icon>mdi-ticket-confirmation</v-icon></v-btn>
    </v-sheet>
  </v-bottom-sheet>
<v-card v-if="!EditingContact"  width="100%" class="stickytopbanner3" id="recordownershipbanner1">
  <v-layout row class="justify-start" id="recordbanner">
      <v-col v-if="Owner">
        <strong>Owner: </strong>{{ Owner.Name+' '+Owner.Surname}}
      </v-col>
      <v-col v-if=" Business_Unit">
        <strong>Business Unit: </strong>{{ Business_Unit.Name}}
      </v-col>
    </v-layout>    
</v-card>
<v-card v-if="EditingContact"  width="100%" class="stickytopbanner3" id="recordownershipbanner2">
  <v-img
  height="160"
  src="@/assets/RABaseBG.jpeg">
    <v-layout row class="justify-center">
     <h2 class="white--text my-3">Contact - Edit View</h2>
    </v-layout>
    <v-card-text>
    <v-text-field v-model="Full_Name" label="Full Name" />
  </v-card-text>
  </v-img>
  <v-list dense>
    <v-list-item>
      <v-list-item-content>
        <strong class="accent--text">Owner:</strong>
      </v-list-item-content>
      <v-list-item-content>
        <v-autocomplete :items="UsersArray" label="Owner" item-text="Full_Name" @change="AssignBU()" v-model="Owner" return-object></v-autocomplete>
      </v-list-item-content>
      <v-list-item-content>
        <strong class="accent--text">Business Unit:</strong>
      </v-list-item-content>
      <v-list-item-content>
        <v-autocomplete :items="BusinessUnitsArray" item-text="Name" label="Business Unit" readonly v-model="Business_Unit" return-object></v-autocomplete>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</v-card> 
  
  
  

  

  


   <v-layout class="justify-center">
     <v-flex lg11 md12>
   
    
        
          
        
          <v-card height="80px" flat class="transparent">
          </v-card>
                
      
          <v-layout row class="justify-start mx-3"><v-icon color="primary" large>mdi-information</v-icon><span class="secondary--text headline" id="General">General</span></v-layout>
 
           <v-layout row v-if="!EditingContact">
        <v-col class="my-5 slightmargin" cols="12" sm="12">
          <v-card class="transparent" flat height="100%"  shaped>
            <v-card shaped dark flat width="90%" class="mx-4 accent text-xs-center elevatedsectionheader"  elevation="6" :style="{ backgroundImage: 'url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' }">    
              <v-card-title class="align-center">
                Section 1<v-spacer></v-spacer>
                <v-btn outlined fab small class="elevation-6 mx-1" dark  v-if="CanEdit" @click="EditingSection1 = !EditingSection1">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle >
               Edit
              </v-card-subtitle>             
            </v-card>

              <v-card height="100%" shaped>
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Full Name</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Full_Name}}</h4>
                      
                      
                      
                    <span v-if="EditingSection1"><v-text-field  v-model="Full_Name"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Company</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      
                      <router-link :to="'/Account/'+Company.id" v-if="Company"><h4 v-if="!EditingSection1" class="light-blue--text">{{Company.Company_Name}}</h4></router-link><a v-if="!Company && HasCompany" class="unpermitted"><h4 v-if="!EditingSection1" class="light-blue--text">{{UnpermittedCompany.Company_Name}}</h4><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedCompany.Company_Name }}"</p></a>
                    <span v-if="EditingSection1"><v-combobox
                        v-if="Company || !Company && !HasCompany"
                        v-model="Company"
                        :items="CompanyArray"
                        item-text="Company_Name"
                        hide-selected
                         label="Company"
                        small-chips
                        solo
                        flat
                        >
                        <template v-slot:prepend-item>
                            <v-list>                            
                              <v-list-item>
                                <v-list-item-content>
                                  <v-btn small @click="PrepareLinkExistingCompany()" color="warning" dark text>Lookup More</v-btn>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn small @click="ActivateAddNewCompanyDialog()" color="green" dark text>Add New</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </template></v-combobox>
                        <div  class="unpermitted" v-if="!Company && HasCompany">
                        <v-combobox
                        v-model="UnpermittedCompany"
                        readonly
                        :items="CompanyArray"
                        item-text="Company_Name"
                        hide-selected
                         label="Company"
                        small-chips
                        solo
                        flat
                        ></v-combobox><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedCompany.Company_Name }}", and therefore cannot change the linked record</p></div>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Website</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                       <a :href="Company.Website" target="blank" v-if="Company">{{Company.Website}}</a>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">History</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{History}}</h4>
                      <v-textarea v-if="EditingSection1" v-model="History"></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Mobile Number</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col> 
                       <a :href="'callto:'+Mobile_Number" target="_top">{{Mobile_Number}}</a>
                    </v-col>
                  </v-row>
                </v-card>
            </v-card>
          </v-col>
          </v-layout>
    
          
          
        
          
        
          
    <!-- RMADmin here -->
    
          <v-card height="80px" flat class="transparent" v-if="userIsAdmin">
          </v-card>
          
          
           <v-card class="folderyellow  yellow lighten-4" shaped id="Documents"  v-if="userIsAdmin">    
              <v-card-title class="align-center secondary white--text">
                <span class="headline">Unsorted Documents</span>
              </v-card-title>
            </v-card>
             <v-card class="my-1" color="rgba(255,255,255,0.6)" outlined  v-if="userIsAdmin">
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>

         
            <v-tabs background-color="transparent"  color="white">
              <v-tab class="foldertab">Unsorted Documents</v-tab>
           <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Document Register'" :PushedUser="UserRecord"/>
            </v-tab-item>
            <v-tab class="foldertab">Unsorted Photos</v-tab>
            <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Images'" :PushedUser="UserRecord"/>
            </v-tab-item>
           </v-tabs>
             </v-card>

          <!-- RMADmin here -->

      
      </v-flex>
  </v-layout>
     <v-layout column class="fab-container" id="myBtn">
    <v-btn fab @click="topFunction()" dark color="red">
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-layout>
  

  
  </v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import SystemTicketsLogging from '@/components/SystemTicketsLogging'
import LookupComponent from '@/components/LookupComponent'
import RMAdministration from '@/components/System/RMAdministration'

export default {
  props: ['AppisDarkMode'],
components: {
  RMAdministration,
  SystemTicketsLogging,
  LookupComponent,
},
    

  data() {
    return {
      EditingPermissionsGeneral: false,
      CanCreate: false,
      CanGet: false,
      CanList: false,
      CanEdit: false,
      CanDelete: false,
      
      selectedEntity: {Name: 'Contacts',
      PrimaryField: 'Full_Name',
      Tables: [
          ]
      },
      selectedRecord: {},
      BannerTabs: [
        ],
      EditingSection1: false,
      TicketLog: false,
      step: 1,
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
      Full_Name: '',
      History: '',
      Created_On: '',
      Created_Onmenu : false,
      Business_Unit:  {id: '', Name: ''},
      UnpermittedBusiness_Unit:  {id: '', Name: ''},
      HasBusiness_Unit: false,
      Business_UnitRelatedParentObj: {},
      Company:  {id: '', Company_Name: ''},
      UnpermittedCompany:  {id: '', Company_Name: ''},
      HasCompany: false,
      CompanyRelatedParentObj: {},
      Created_By:  {id: '', Full_Name: ''},
      UnpermittedCreated_By:  {id: '', Full_Name: ''},
      HasCreated_By: false,
      Created_ByRelatedParentObj: {},
      Owner:  {id: '', Full_Name: ''},
      UnpermittedOwner:  {id: '', Full_Name: ''},
      HasOwner: false,
      OwnerRelatedParentObj: {},
      Mobile_Number:  '',
      Website: '',
      Business_UnitName: null,
      CompanyCompany_Name: null,
      Created_ByFull_Name: null,
      OwnerFull_Name: null,
      
      Created_Onmenu: false,
      
      
      
      AddNewParentLookupDialog: false,
      ViewExistingLookupDialog: false,
      FieldFilter: '',
      ParentTitle: '',
      ParentCollection:  '',
      NewParentPrimary: '',
      ParentLookup: '',
      NewParentAdditional: [],
      LookupArray: [],
      LookupArrayHeaders: [],
      Business_UnitArray: [],
      Business_Unitfilter: {Name: ''},
      
      CompanyArray: [],
      Companyfilter: {Company_Name: ''},
      
      Created_ByArray: [],
      Created_Byfilter: {Full_Name: ''},
      
      OwnerArray: [],
      Ownerfilter: {Full_Name: ''},
      
      EditingContact: false,
      dialog: false,
      initialize: '',
      search: '',
      ContactData: {},
      headers: [
      
        
        { text: 'Full Name', value: 'Full_Name'},
        
        
        
        { text: 'Business Unit', value: 'Business_Unit.Name'},
        
        
        { text: 'Company', value: 'Company.Company_Name'},
        
        
        { text: 'Owner', value: 'Owner.Full_Name'},
        
        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      EditedContactsnackbar: false,
      ContactEditdialog: false,
      newContactdialog: false,
      undefined: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
      },
      editedItem: {
        id: '',
      },
      UsersArray:[],
      BusinessUnitsArray: [],
      
      Manager: {Name: '', Surname: '', id: '', Full_Name: ''},
      
      Name:  '',
      Description:  '',
      Parent:  {id: '', Name: ''},
      Parentname: null,
      ParentArray: [],
      Parentfilter: {Name: ''},
      EditingBusinessUnit: false,
      ParentsToUpdate: [],
      ChildArrayQuery: [],
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: '',
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      getAll: false,
      getUnitDown: false,
      getUserUnit: false,
      getOwner: false,
      IsAdmin: false,
      listAll: false,
      listUnitDown: false,
      listUserUnit: false,
      listOwner: false,
      createAll: false,
      createUnitDown: false,
      createUserUnit: false,
      createOwner: false,
      updateAll: false,
      updateUnitDown: false,
      updateUserUnit: false,
      updateOwner: false,
      deleteAll: false,
      deleteUnitDown: false,
      deleteUserUnit: false,
      deleteOwner: false,
      AllgetRoles: [
  "Default get All",
  "Default All"
],
      UnitDowngetRoles: [
  "Default Unit Down",
  "Default get UnitDown"
],
      UserUnitgetRoles: [
  "Default Unit",
  "Default get UserUnit"
],
      OwnergetRoles: [
  "Default Owner"
],
      AlllistRoles: [
  "Default list All",
  "Default All"
],
      UnitDownlistRoles: [
  "Default list UnitDown",
  "Default Unit Down"
],
      UserUnitlistRoles: [
  "Default Unit",
  "Default list UserUnit"
],
      OwnerlistRoles: [
  "Default Owner"
],
      AllcreateRoles: [
  "Default Create All",
  "Default All"
],
      UnitDowncreateRoles: [
  "Default create unitdown",
  "Default Unit Down"
],
      UserUnitcreateRoles: [
  "Default Unit",
  "Default create UserUnit"
],
      OwnercreateRoles: [
  "Default Owner"
],
      AllupdateRoles: [
  "Default update ALL",
  "Default All"
],
      UnitDownupdateRoles: [
  "Default update Unit Down",
  "Default Unit Down"
],
      UserUnitupdateRoles: [
  "Default Unit",
  "Default update UserUnit"
],
      OwnerupdateRoles: [
  "Default Owner"
],
      AlldeleteRoles: [
  "Default delete ALL",
  "Default All"
],
      UnitDowndeleteRoles: [
  "Default delete UnitDown",
  "Default Unit Down"
],
      UserUnitdeleteRoles: [
  "Default Unit",
  "Default delete UserUnit"
],
      OwnerdeleteRoles: [
  "Default Owner"
],
      helpsheet: false,
      UndefinedMandatoryFields: [],
      RelatedObj: {},
      
    }
    
    
  },

  created(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.IntranetViewToggle(false)


this.GetRequestingUser()
this.GetUsers()
this.GetBusinessUnits()
window.addEventListener('scroll', this.handleScroll)

  },

  computed: {
      userIsWikiModerator () {
    if(this.UserRecord.ModerationPermissions){
      let match = this.UserRecord.ModerationPermissions.find(obj => obj === 'Wiki')
      if(match){
        return true
      }
    }
  
},
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    BusinessUnitsStore(){
      return this.$store.state.BusinessUnitsArray
    },
    AccountsStore(){
      return this.$store.state.AccountsArray
    },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    
    HeaderDarkLightStyle(){
      if(this.AppisDarkMode){
        return 'white--text font-weight-light'
      }
      else{
        return 'primary--text font-weight-light'
      }
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    userBU () {
      return this.$store.state.buobj
    },
  Created_OnObject(){
    return this.DateFormatter(this.Created_On)
  },  
  
          
    },
    watch: {
    userLoggedIn (value) {
      if (value !== null && value !== undefined) {
        //this.$router.push('/')
      }
    }
  },
  methods: {
    Documentoptions(value,array){
        if(value.ID){
          let option = array.find(obj => obj.ID === value.ID)
        return option.Name
        }
        else{
          return value +'OLD'
        }
        
      },
      IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
    
      
    async GetRequestingUser(){
      
        var vm = this;
      //await firebase.auth().onAuthStateChanged(function(user) {
        if (this.userLoggedIn) {

            //db.collection('users').doc(user.uid).onSnapshot(snapshot => {
            //  var userdetails = snapshot.data()

              vm.UserRecord = this.userLoggedIn
              vm.UserRoles = this.userLoggedIn.rolesarrayQuery
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
              console.log(vm.userBU)
              // if(vm.UserRecord.Business_Unitid){
                  vm.UserBusinessUnitID = vm.userBU.id
                  console.log(vm.UserBusinessUnitID)
                  // db.collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
                  // var businessunitdata = snapshot.data()
                  vm.UserBusUnitChildren = vm.userBU.childarrayQuery
                  vm.UserBusUnitParents = vm.userBU.parentarray
                  console.log(vm.UserBusUnitChildren)
              //     })
              // }
              
              
              vm.UserRoles = vm.UserRecord.rolesarrayQuery
              vm.UserBusinessUnitID = vm.UserRecord.Business_Unitid
              console.log(vm.UserBusinessUnitID)
              db.collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
             var businessunitdata = snapshot.data()
             vm.UserBusUnitChildren = businessunitdata.childarrayQuery
             vm.UserBusUnitParents = businessunitdata.parentarray
             console.log(vm.UserRoles)
             let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
              
              vm.BURolesCheck(vm.AllgetRoles,vm.UnitDowngetRoles,vm.UserUnitgetRoles,vm.OwnergetRoles).then(PermissionLevel => {
                console.log(PermissionLevel)
                if(PermissionLevel === 4 || vm.IsAdmin === true){
                  vm.listAll = true
                  console.log('Permissions for Contacts is All')
                  vm.GetContactBUCheck()
                }
                else if(PermissionLevel === 3){
                  vm.listUnitDown = true
                  console.log('Permissions for Contacts is UnitDown')
                  vm.GetContactBUCheck()
                }
                else if(PermissionLevel === 2){
                  vm.listUserUnit = true
                  console.log('Permissions for Contacts is UserUnit')
                  vm.GetContactBUCheck()
                }
                else if(PermissionLevel === 1 || vm.IsAdmin){
                  vm.listOwner = true
                  vm.AppointmentsQuery = vm.STDAppointmentsQuery.where('Ownerid', '==', vm.UserRecord.id)
                  console.log('Permissions for Contacts is Owner')
                  vm.GetContactBUCheck()
                }
                else {
                  vm.RoutetoLoginPage()
                    }
              })              
              
                    
              let BusinessUnitsBusiness_UnitQuery = db.collection('businessunits')
              vm.GetBusinessUnitsBusiness_UnitArrayandLookupValue(BusinessUnitsBusiness_UnitQuery)
              

                
                    
            
              let allAccountsCompanylistRoles = [
  "Default All",
  "Default list All"
]
              let peerAccountsCompanylistRoles = [
  "Default Unit Down",
  "Default list UnitDown"
]
              let parentAccountsCompanylistRoles = [
  "Default Unit",
  "Default list UserUnit"
]
              let childAccountsCompanylistRoles = [
  "Default Owner"
]
              let AccountsCompanyCollectionReference = db.collection('accounts')
              vm.BURolesCheck(allAccountsCompanylistRoles,peerAccountsCompanylistRoles,parentAccountsCompanylistRoles,childAccountsCompanylistRoles).then(PermissionLevel => {
                  console.log(PermissionLevel)
                  if(PermissionLevel === 4 || vm.IsAdmin === true){
                  let AccountsCompanyQuery =  AccountsCompanyCollectionReference
                  vm.GetAccountsCompanyArrayandLookupValue(AccountsCompanyQuery)
                }
                else if(PermissionLevel === 3){
                  let AccountsCompanyQuery =  AccountsCompanyCollectionReference.where('Business_Unitid', 'in', vm.UserBusUnitChildren);
                  vm.GetAccountsCompanyArrayandLookupValue(AccountsCompanyQuery)
                }
                else if(PermissionLevel === 2){
                  let AccountsCompanyQuery =  AccountsCompanyCollectionReference.where('Business_Unitid', '==', vm.UserBusinessUnitID);
                  vm.GetAccountsCompanyArrayandLookupValue(AccountsCompanyQuery)
                }
                else if(PermissionLevel === 1){
                  let AccountsCompanyQuery =  AccountsCompanyCollectionReference.where('Ownerid', '==', vm.UserRecord.id);
                  vm.GetAccountsCompanyArrayandLookupValue(AccountsCompanyQuery)
                }
                else {
                  //vm.RoutetoErrorPage()
                }
                })

                
                    
              let UsersCreated_ByQuery = db.collection('users')
              vm.GetUsersCreated_ByArrayandLookupValue(UsersCreated_ByQuery)
              

                
                    
              let UsersOwnerQuery = db.collection('users')
              vm.GetUsersOwnerArrayandLookupValue(UsersOwnerQuery)
              

                              
              })
            //})
           
          }
      else {
        vm.RoutetoLoginPage()
          }
          //})
      },
      CustomRolesCheck(Roles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      BURolesCheck(AllRoles, PeerRoles, ParentRoles, ChildRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Alltest = AllRoles.find(obj => obj == role)
              let Peertest = PeerRoles.find(obj => obj == role)
              let Parenttest = ParentRoles.find(obj => obj == role)
              let Childtest = ChildRoles.find(obj => obj == role)
                if(Alltest){
                  PermissionLevel = 4
                }                
                else if(Peertest){
                  if(PermissionLevel<3){
                    PermissionLevel = 3
                  }
                }                  
                else if(Parenttest){
                  if(PermissionLevel<2){
                    PermissionLevel = 2
                  }
                } 
                else if(Childtest){
                  if(PermissionLevel<1){
                    PermissionLevel = 1
                  }
                  
                } 
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
      
      GetContact() {
        let vm = this
        if(vm.IsAdmin){
          vm.CanEdit = true
        }
            db.collection('contacts').doc(this.$route.params.id).onSnapshot(snapshot => {
            var contact = snapshot.data()
            contact.id = this.$route.params.id
            
            this.selectedRecord = {id: this.$route.params.id, Full_Name: contact.Full_Name }
            this.RelatedObj = {id: this.$route.params.id, Full_Name: contact.Full_Name,identifier: 'Full_Name',collection: 'contacts',Link:'Contact' }
            this.ContactData = contact
            this.ContactData.id = this.$route.params.id
            this.$emit('PushRecordName',this.ContactData.Full_Name)
            if(typeof contact.Owner !== 'undefined'){
              let tempOwner = contact.Owner
              this.Owner = this.UsersArray.find(user => user.id == tempOwner.id)
            }
            
            if(typeof contact.Owner !== 'undefined'){
              this.Business_Unit = contact.Business_Unit
            }        

            this.Full_Name = contact.Full_Name
            this.editedItem.Full_Name = contact.Full_Name
            //I think API Trigger should be here, outside the first if loop on fields
            this.History = contact.History
            this.editedItem.History = contact.History
            //I think API Trigger should be here, outside the first if loop on fields
            //I think API Trigger should be here, outside the first if loop on fields
              if(contact.Created_On && typeof contact.Created_On !== 'string'){
            this.Created_On = format(contact.Created_On.toDate(),'yyyy-MM-dd')
            this.editedItem.Created_On = contact.Created_On
              }
            if(contact.Business_Unit){
            this.Business_UnitRelatedParentObj = {id: contact.Business_Unit.id, Full_Name: contact.Full_Name,identifier: 'Name',collection: 'businessunits',Link: 'BusinessUnit' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
            this.Business_Unit = this.Business_UnitArray.find(business_unit => business_unit.id === contact.Business_Unitid)
            if(typeof contact.Business_Unitid !== 'undefined' && contact.Business_Unitid !== ''){
              this.HasBusiness_Unit = true
              if(typeof this.Business_Unit === 'undefined'){
                this.UnpermittedBusiness_Unit = {id: contact.Business_Unitid,Name: contact.Business_Unit.Name}
              }
            }
            this.editedItem.Business_Unit = contact.Business_Unit
            if(contact.Company){
            this.CompanyRelatedParentObj = {id: contact.Company.id, Full_Name: contact.Full_Name,identifier: 'Company_Name',collection: 'accounts',Link: 'Account' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
            this.Company = this.CompanyArray.find(company => company.id === contact.Companyid)
            if(typeof contact.Companyid !== 'undefined' && contact.Companyid !== ''){
              this.HasCompany = true
              if(typeof this.Company === 'undefined'){
                this.UnpermittedCompany = {id: contact.Companyid,Company_Name: contact.Company.Company_Name}
              }
            }
            this.editedItem.Company = contact.Company
            if(contact.Created_By){
            this.Created_ByRelatedParentObj = {id: contact.Created_By.id, Full_Name: contact.Full_Name,identifier: 'Full_Name',collection: 'users',Link: 'User' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
            this.Created_By = this.Created_ByArray.find(created_by => created_by.id === contact.Created_Byid)
            if(typeof contact.Created_Byid !== 'undefined' && contact.Created_Byid !== ''){
              this.HasCreated_By = true
              if(typeof this.Created_By === 'undefined'){
                this.UnpermittedCreated_By = {id: contact.Created_Byid,Full_Name: contact.Created_By.Full_Name}
              }
            }
            this.editedItem.Created_By = contact.Created_By
            if(contact.Owner){
            this.OwnerRelatedParentObj = {id: contact.Owner.id, Full_Name: contact.Full_Name,identifier: 'Full_Name',collection: 'users',Link: 'User' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
            this.Owner = this.OwnerArray.find(owner => owner.id === contact.Ownerid)
            if(typeof contact.Ownerid !== 'undefined' && contact.Ownerid !== ''){
              this.HasOwner = true
              if(typeof this.Owner === 'undefined'){
                this.UnpermittedOwner = {id: contact.Ownerid,Full_Name: contact.Owner.Full_Name}
              }
            }
            this.editedItem.Owner = contact.Owner
            this.Mobile_Number = contact.Mobile_Number
            this.editedItem.Mobile_Number = contact.Mobile_Number
            //I think API Trigger should be here, outside the first if loop on fields
            //I think API Trigger should be here, outside the first if loop on fields
    
            })
          
        },
      
      
      GetContactBUCheck() {
        if(this.IsAdmin === false && this.listAll === false && this.listUnitDown === false && this.listUserUnit === false && this.listOwner === false){
          this.RoutetoErrorPage()
        }
    
      else {
      this.GetContact()
      }
  },


    
      
      
      
      getImgUrlRefDocWiki(item) {
        var images = require.context('@/assets/', false)
          if(item.FileType.includes('pdf')){
            return images('./PDF_DOC.png')
          }
          else{
            return images('./logo.png')
          }    
        },
      RelatedRecordsArray(array,arrayname,headers,headersname){
      console.log('getting related records ',arrayname)
      this[arrayname] = array
      this[headersname] = headers
    },
      DateFormatterwithTimeXML(date){
        if(date){
              console.log(date)
              console.log(typeof date)
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2].split('T')[0]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              var hr = date.split('-')[2].split('T')[1].split(':')[0]
              var min = date.split('-')[2].split('T')[1].split(':')[1]
              var sec = date.split('-')[2].split('T')[1].split(':')[2]
              // if (min < 10) {
              //     min = "0" + min;
              // }
              var ampm = "am";
          
              console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
              return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
              }
            else{
              return null
            }
      },
      
        UpdateContact(){
        this.UndefinedMandatoryFields = []
      const EditedContact = {
    Full_Name: this.Full_Name,
    
    //here we should now make room for mandatory lookupfields
        Business_Unit: {Name: this.Business_Unit.Name,id: this.Business_Unit.id},
        Business_Unitid: this.Business_Unit.id,
    
    //here we should now make room for mandatory lookupfields
        Owner: {Full_Name: this.Owner.Full_Name,id: this.Owner.id},
        Ownerid: this.Owner.id,
    
        }
      for(var prop in EditedContact) {
          if (EditedContact.hasOwnProperty(prop)) {
            if(typeof EditedContact[prop] === 'undefined'){
              this.UndefinedMandatoryFields.push(prop)
            }
          else {
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
            }
            }
      
          else{
            console.log('something is amook')
          }
        }
        console.log(this.UndefinedMandatoryFields)
      
      //not sure why we have this... 
      //const EditedUser = this.Owner
      
      //this below database "update" is applicable to the update of mandatory fields
      if(this.UndefinedMandatoryFields.length === 0){
        if(this.Company && this.Company.id){
        this.GetWebsite(this.Company.id)
        }
      db.collection('contacts').doc(this.$route.params.id).update({
        Full_Name: EditedContact.Full_Name,
        
    //here we should now make room for mandatory lookupfields
        Business_Unit: EditedContact.Business_Unit,
        Business_Unitid: EditedContact.Business_Unitid,
                  
        
    //here we should now make room for mandatory lookupfields
        Owner: EditedContact.Owner,
        Ownerid: EditedContact.Ownerid,
                  
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date(),
        
              });
              this.close()
              this.EditedContactsnackbar = true
              this.CancelContactEditing()
              //exactly what we did here, what we incorrectly did here with lookupfields, we will do with optional fields. So this shoudl change to a v-for on optional fields, and same logic, if undefined on each, then nothing otherwise update record with said field
              if(typeof this.Full_Name !== 'undefined'){                  
                  let Full_NameQuery = []
                  let Full_NameStringArray = this.Full_Name.split('')
                  var lowertext = "";                
                  var p;
                    let buildup = ''
                   console.log(Full_NameStringArray.length,Full_NameStringArray)
                    for (p = 0; p < Full_NameStringArray.length; p++) {
                        buildup = buildup+ Full_NameStringArray[p]
                          lowertext += buildup.toLowerCase() + ",";
                          }                   
                    Full_NameQuery = lowertext.split(',')  
                    Full_NameQuery.length = Full_NameQuery.length-1
                   db.collection('contacts').doc(this.$route.params.id).update({
                     Full_NameQuery: Full_NameQuery,
                    Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                    Modified_Byid: this.UserRecord.id,
                    Modified_On: new Date()
                     })
                 }  
              
                if(typeof this.History !== 'undefined'){
                   db.collection('contacts').doc(this.$route.params.id).update({
                     History: this.History,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                
              if(typeof this.Company !== 'undefined'){
                db.collection('contacts').doc(this.$route.params.id).update({
                  Company: {Company_Name: this.Company.Company_Name,id: this.Company.id},
                  Companyid: this.Company.id,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                      })
              }
                if(typeof this.Mobile_Number !== 'undefined'){
                   db.collection('contacts').doc(this.$route.params.id).update({
                     Mobile_Number: this.Mobile_Number,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                 if(this.Company && typeof this.Company.Website !== 'undefined'){
                   db.collection('contacts').doc(this.$route.params.id).update({
                     Website: this.Company.Website,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                     })
                 }
                
      }
      else{
        alert('you need to capture the values in '+this.UndefinedMandatoryFields)
        this.UndefinedMandatoryFields.map(field => {
          console.log('getting element by id '+field)
          let element = document.getElementById(field)
          
          element.setAttribute("class", "incompletedmandatory")
          console.log('here is element')
          console.log(element)
        })
      }
      },
    ViewActivityItem (item) {
        this.$router.push('/Activity/'+item.id)
      },

      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      deleteItem (item) {
        const index = this.ContactsArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('contacts').doc(item.id).delete() && this.ContactsArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.ContactsArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.UndefinedMandatoryFields.map(prop => {
        
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
              
            })
              this.EditingSection1 = false
      },
    ResetSearch() {
      this.search = ''
    },
    
    CancelContactEditing(){
      this.EditingContact = false
    },
    EditContact() {
      this.EditingContact = !this.EditingContact
    },
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if(mybutton){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          mybutton.style.display = "block";
        }
        else {
          mybutton.style.display = "none";
        }
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    NavigatetoTab(item){
      let tabelmnt = document.getElementById(item.Name)
      tabelmnt.scrollIntoView();
    },
    CancelSubmitSystemTicket(){
      this.TicketLog = false
      this.helpsheet = false
    },
    ActivateTicketLog(){
      this.TicketLog = true
    },
    
      
  GetBusinessUnitsBusiness_Unit() {
  db.collection('businessunits').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Business_UnitArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetBusinessUnitsBusiness_UnitArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.BusinessUnitsStore.length > 0){
      //alert('got from store')
      vm.Business_UnitArray = vm.BusinessUnitsStore
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setBusinessUnitsArray',
                    getter: 'getBusinessUnitsArray',
                    arrayname: 'BusinessUnitsArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.BusinessUnitsStore)
                        vm.Business_UnitArray = vm.BusinessUnitsStore
                        return vm.GetBusiness_Unit();
                        })
    }
  },

  GetBusiness_Unit(){
    this.Business_Unit = this.Business_UnitArray.find(business_unit => business_unit.id === this.ContactData.Business_Unitid)
  },

  //GetBusinessUnitsBusiness_Unit(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.Business_UnitArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetBusinessUnitsBusiness_Unit finished')
 //   })
 // })
//    })
//  },

  GetAccountsCompanyArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.AccountsStore.length > 0){
      //alert('got from store')
      vm.CompanyArray = vm.AccountsStore
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setAccountsArray',
                    getter: 'getAccountsArray',
                    arrayname: 'AccountsArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.AccountsStore)
                        vm.CompanyArray = vm.AccountsStore
                        return vm.GetCompany();
                        })
    }
  },

  GetCompany(){
    this.Company = this.CompanyArray.find(company => company.id === this.ContactData.Companyid)
  },

  //GetAccountsCompany(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.CompanyArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetAccountsCompany finished')
 //   })
 // })
//    })
//  },

  GetUsersCreated_By() {
  db.collection('users').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Created_ByArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetUsersCreated_ByArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.UsersStore.length > 0){
      //alert('got from store')
      vm.Created_ByArray = vm.UsersStore
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setUsersArray',
                    getter: 'getUsersArray',
                    arrayname: 'UsersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.UsersStore)
                        vm.Created_ByArray = vm.UsersStore
                        return vm.GetCreated_By();
                        })
    }
  },

  GetCreated_By(){
    this.Created_By = this.Created_ByArray.find(created_by => created_by.id === this.ContactData.Created_Byid)
  },

  //GetUsersCreated_By(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.Created_ByArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetUsersCreated_By finished')
 //   })
 // })
//    })
//  },

  GetUsersOwner() {
  db.collection('users').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.OwnerArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetUsersOwnerArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.UsersStore.length > 0){
      //alert('got from store')
      vm.OwnerArray = vm.UsersStore
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setUsersArray',
                    getter: 'getUsersArray',
                    arrayname: 'UsersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.UsersStore)
                        vm.OwnerArray = vm.UsersStore
                        return vm.GetOwner();
                        })
    }
  },

  GetOwner(){
    this.Owner = this.OwnerArray.find(owner => owner.id === this.ContactData.Ownerid)
  },

  //GetUsersOwner(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.OwnerArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetUsersOwner finished')
 //   })
 // })
//    })
//  },



CancelAddParentDialog(){
  this.AddNewParentLookupDialog = false
  this.ParentTitle = ''
  this.ParentCollection = ''
  this.NewParentPrimary = ''
  this.ParentLookup = ''
  this.NewParentAdditional = []
  this.FieldFilter = ''
  this.LookupArray = []
  this.LookupArrayHeaders = []
},

CancelSelection(){
  this.ViewExistingLookupDialog = false
},

LinkParent(parent){
  console.log('we will link this one',parent)
  console.log('array',this[this.ParentLookup.Array])
  let parentdisplay = this[this.ParentLookup.Array].find(obj => obj.id === parent[this.ParentLookup.Propid]) 
  this[this.ParentLookup.LookupFieldName] = parentdisplay
  console.log('to this one like this',this[this.ParentLookup.LookupFieldName])
},
PrepareLinkExistingBusiness_Unit(){
this.CancelAddParentDialog()
this.PrepareDataParentBusiness_Unit()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Business Unit'
      this.LookupArray = this.Business_UnitArray
      this.LookupArrayHeaders = [
        { text: 'Description', value: 'Description',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
      ]
},

PrepareDataParentBusiness_Unit(){
  this.ParentTitle = 'Business_Unit'
  this.ParentCollection = 'businessunits'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Business Unit', Prop: 'Name', Propid: 'Business_Unitid', Value: '',Array: 'Business_UnitArray',LookupFieldName: 'Business_Unit'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Business Unit', Prop: 'Business_Unit', Value: '',LookupFieldName: 'Business_Unit'}     
  
},

ActivateAddNewBusiness_UnitDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentBusiness_Unit()
},


PrepareLinkExistingCompany(){
this.CancelAddParentDialog()
this.PrepareDataParentCompany()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Company'
      this.LookupArray = this.CompanyArray
      this.LookupArrayHeaders = [
        { text: 'Business Unit', value: 'Business_Unit.Name',class: "overline"},
        { text: 'Company Name', value: 'Company_Name',class: "overline"},
        { text: 'Owner', value: 'Owner.Full_Name',class: "overline"},
        { text: 'Trading As', value: 'Trading_As',class: "overline"},
      ]
},

PrepareDataParentCompany(){
  this.ParentTitle = 'Company'
  this.ParentCollection = 'accounts'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Company', Prop: 'Company_Name', Propid: 'Companyid', Value: '',Array: 'CompanyArray',LookupFieldName: 'Company'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Company', Prop: 'Company', Value: '',LookupFieldName: 'Company'}
        let Trading_AsObj = {Type: 'Single Line Text', Name: 'Trading As',Prop: 'Trading_As'}
  this.NewParentAdditional.push(Trading_AsObj )     
  
},

ActivateAddNewCompanyDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentCompany()
},


PrepareLinkExistingCreated_By(){
this.CancelAddParentDialog()
this.PrepareDataParentCreated_By()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Created By'
      this.LookupArray = this.Created_ByArray
      this.LookupArrayHeaders = [
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
      ]
},

PrepareDataParentCreated_By(){
  this.ParentTitle = 'Created_By'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Created By', Prop: 'Full_Name', Propid: 'Created_Byid', Value: '',Array: 'Created_ByArray',LookupFieldName: 'Created_By'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Created By', Prop: 'Created_By', Value: '',LookupFieldName: 'Created_By'}
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj )
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj )
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj )
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj )
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj )     
  
},

ActivateAddNewCreated_ByDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentCreated_By()
},


PrepareLinkExistingOwner(){
this.CancelAddParentDialog()
this.PrepareDataParentOwner()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Owner'
      this.LookupArray = this.OwnerArray
      this.LookupArrayHeaders = [
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
      ]
},

PrepareDataParentOwner(){
  this.ParentTitle = 'Owner'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Owner', Prop: 'Full_Name', Propid: 'Ownerid', Value: '',Array: 'OwnerArray',LookupFieldName: 'Owner'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Owner', Prop: 'Owner', Value: '',LookupFieldName: 'Owner'}
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj )
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj )
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj )
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj )
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj )     
  
},

ActivateAddNewOwnerDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentOwner()
},



      
    
      
      
    
      
    
      
    GetWebsite(docid){
      console.log('calling Website with '+docid)
      db.collection('').doc(docid).onSnapshot(snapshot => {
        this.Company = snapshot.data()
        this.Website = this.Company.Website
      })
    },
    
      
            
      
    
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
},
    
          
      
     
      
      

      
      
      
      ResetFilters() {},
      
      
      
    ProcessDeleteItem(item,itemarrayname,collection){
      let arrayindex = this[itemarrayname].indexOf(item)
      this[itemarrayname].splice(arrayindex,1)
      let itemref = firebase.storage().ref(item.StorageRef);
      itemref.delete().then(function() {									
          // File deleted successfully			
          db.collection(collection).doc(this.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
        }).catch(function(error) {									
          // Uh-oh, an error occurred!									
        });	
      
    },
    MoveFiletoNewLocation(currentdownloadurl, destinationPath) {									
          let vm = this									
         return new Promise(function(resolve, reject) {									
       									
        fetch(currentdownloadurl,{									
                      method: 'GET', // *GET, POST, PUT, DELETE, etc.									
                      mode: 'cors', // no-cors, *cors, same-origin									
                  }).then(htmlReturn => {									
                    let blob = htmlReturn.blob().then(b => {									
                      console.log(destinationPath)									
                      var storageRef = firebase.storage().ref(destinationPath)									
                      var uploadTask = storageRef.put(b);									
                      uploadTask									
                          .then(snapshot => snapshot.ref.getDownloadURL())									
                            .then((url) => {									
                              resolve(url)									
                  })									
                })									
            })									
            // let storageRef = firebase.storage().ref("Unsorted");									
            // let fileref = storageRef.child(item.Name)									
            // fileref.delete()									
         })	
    },
    RemoveSingleItemfromTable(item,itemarrayname,collection){
      console.log(this.selectedEntity)
        const NewDoc = {									
                Name: item.Name,									
                FileType: item.FileType,									
                OldPath: 'No Reference',																		
                url: item.url,									
                Status: {									
                ID: 1000003,									
                Name: 'Assign Table'},                								
                Created_By: item.Created_By,
                Created_Byid: item.Created_Byid,          
                Created_On: item.Created_On,	
                ModifiedDate: item.ModifiedDate,
                Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                Modified_Byid: this.UserRecord.id,
                Modified_On: new Date(),
                Record: this.selectedRecord,
                RecordName: this.selectedRecord.Job_Number,
                Entity: this.selectedEntity
                
                								
              									
            }
            
        NewDoc.StorageRef = this.selectedEntity.Name.split(' ').join('')+'/'+this.$route.params.id+'/Unsorted/'+ NewDoc.Name		
        this.MoveFiletoNewLocation(item.fileurl, NewDoc.StorageRef).then(url => {
          NewDoc.url = url			
          console.log(NewDoc)		
          console.log(item)				
                      db.collection('RMDocuments').add(NewDoc).then(doc => {									
                        console.log('okay loaded it')									
                    })
          let itemref = firebase.storage().ref(item.StorageRef);
          itemref.delete().then(function() {									
              // File deleted successfully			
            }).catch(function(error) {									
              // Uh-oh, an error occurred!									
            });	
          let arrayindex = this[itemarrayname].indexOf(item)
          this[itemarrayname].splice(arrayindex,1)
          db.collection(collection).doc(this.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
          db.collection(collection).doc(this.$route.params.id).collection('RMUnsortedDocuments').add(NewDoc).then(doc => {
            NewDoc.id = doc.id
            this[itemarrayname].push(NewDoc)
          console.log('added new item')
          })
        })				
    },
    
      
      
  
        
        
        
        
        
      
      GetUsers(){
      db.collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },
    AssignBU(){
      let userid = this.Owner.id
      db.collection('users').doc(userid).onSnapshot(snapshot => {
        let userdata = snapshot.data()
        let BUId = userdata.Business_Unitid
        db.collection('businessunits').doc(BUId).onSnapshot(snapshot => {
        let BUdata = snapshot.data()
        this.Business_Unit = BUdata
        this.Business_Unit.id = BUId
      })
      })
    },
    GetBusinessUnits(){
      db.collection('businessunits').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.BusinessUnitsArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },
    
    

    
      
  }
}


</script>

<style>

#mySidenav a {
  position: fixed;
  right: -80px;
  transition: 0.3s;
  padding: 15px;
  width: 150px;
  text-decoration: none;
  font-size: 12px;
  color: white;
  border-radius: 0 5px 5px 0;
  top: 55px;
}

#mySidenav a:hover {
  right: 0;
}

#about {
  
  background-color: #4CAF50;
}

.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}
.fab-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 100
}
.stickytopright {
  position: fixed;
  top: 100px;
  right: 50px;
  z-index: 100
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 100
}
.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
.HTMLTable{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
color:#757575;
}
.HTMLTableContent{
border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}

      
</style>
  
            
        